<template>
  <div>
    <client-only v-if="page?.content">
      <EpiEditButton
        v-if="showEpiButton && isEpiDefault"
        :page-id="page.content.contentLink.id.toString()"
        class="mobileOnly:!hidden"
      />
    </client-only>
    <img
      v-if="isNOMarket"
      :src="'/logos/swm-logotype.svg?url'"
      class="w-200 object-contain mx-auto mb-32 mt-112 sm:mt-112 md:mt-0"
      style="width: 200px;"
      alt="Swedish Match Logo"
    >
    
    <Receipt v-if="isReceipt" />
    <EpiserverPage
      v-if="page?.content"
      :model="page.content"
    />
    <EpiserverPage
      v-else-if="result?.content"
      :model="result.content"
    />
  </div>
</template>

<script setup lang="ts">
import EpiEditButton from '~/components/episerver/EpiEditButton.vue';
import { storeToRefs } from 'pinia';
import type { MetaBlock } from '~/content-types';

import { ContentResolver, defaultConfig, ResolvedContentStatus } from '@episerver/content-delivery';
import { useGlobalContentStore } from '~/store/globalContent';
import { type Head } from '@unhead/schema';
import Receipt from '~/components/my-page/Receipt.vue';
import { useApplicationInsights } from '~/store/applicationInsights';
import type { IRedirectsResult, IICommandResultBase, ICommandResult } from '~/api-types';
import * as Sentry from '@sentry/vue';

// Ui
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { usePageContentStore } from '~/store/pageContent';
import { ref } from 'vue';

const uiStore = useUiStore();
const pageStore = usePageContentStore();
const globalContentStore = useGlobalContentStore();
const runtimeConfig = useRuntimeConfig();
const marketSettings = useGlobalContentStore().marketSettings;
const acceptLanguage = globalContentStore.getAcceptLanguage;
const host = globalContentStore.host;
const appInsights = useApplicationInsights();
const { apiGet, apiPost } = useApiFetch();

// Data loading
const { public: { googleSiteVerification, websiteUrl }, public: { environmentName } } = useRuntimeConfig();
const route = useRoute();
const headers = useRequestHeaders();
const eclubId = ref(route.query.eclub);

// Epibutton
const { showEpiButton  } = storeToRefs(uiStore);
const { page, isEpiEdit, isEpiDefault  } = storeToRefs(pageStore);

// * * * Setup content delivery
defaultConfig.apiUrl = process.server ? runtimeConfig.contentDeliveryApi : runtimeConfig.public.contentDeliveryApi;
defaultConfig.selectAllProperties = true;
defaultConfig.expandAllProperties = true;

defaultConfig.getHeaders = () => {
  return new Promise((resolve) => {
    if (process.server) {
      // Forward the cookie header when rendering server-side,
      // making these calls authenticated as well.
      //Use forwarded host to get relative URLs in the response
      if (headers['cookie']) {
        resolve({
          cookie: headers['cookie'],
          'X-Epi-Host': headers['x-forwarded-host'],
          'x-forwarded-for': headers['x-forwarded-for'],
          'True-Client-IP': headers['true-client-ip'],
          'Accept-Language': acceptLanguage,
          'User-Agent': headers['user-agent'],
          'CF-IPCountry': headers['cf-ipcountry'],
        });
      }
      resolve({
        'X-Epi-Host': headers['x-forwarded-host'],
        'x-forwarded-for': headers['x-forwarded-for'],
        'True-Client-IP': headers['true-client-ip'],
        'Accept-Language': acceptLanguage,
        'User-Agent': headers['user-agent'],
        'CF-IPCountry': headers['cf-ipcountry'],
      });
    }
    resolve({
      'Accept-Language': acceptLanguage,
    });
  });
};

if (eclubId.value && process.client) {
  try {
    await globalContentStore.fetchAntiForgeryToken();
    const resolveContactIdResult = await apiPost<ICommandResult<string>>(
      'user/softid',
      {
        'countryCode': marketSettings.countryCode,
        'eclub': eclubId.value,
      }
    );
    const contactId = resolveContactIdResult?.value;
    if (contactId) {
      uiStore.setContactId(contactId);
    }
  } catch (e) {
    console.log(e);
  }
}

const isNOMarket = computed(() => {
  return marketSettings.countryCode === 'NO';
});

const isReceipt = ref(false);

if (route.fullPath.includes('order-receipt')) {
  isReceipt.value = true;
}

// * * * Loading page
const { data } = await useAsyncData('dataloading', async()=> {
  try {
    if (isReceipt.value) {
      return;
    }
    return await pageStore.loadContent(route.fullPath);
  } catch (e) {
    console.log(e);
    return false;
  }
});
// * * * Start output
const head = {} as Head;

// * * * 404 content
const result = ref<any>({});

const isVirutalPageListing = !isReceipt.value && page?.value?.content?.url != undefined && page?.value?.content?.contentType.includes('ProductListingPage') && page?.value?.content?.url && page?.value?.content?.url != route.path;

if (!isReceipt.value && (data.value !== ResolvedContentStatus.Resolved || isVirutalPageListing)) {
  const currentMarket = marketSettings.markets.find((i) => {
    return i.isCurrentMarket && marketSettings.contentLanguage === i.language;
  });
  const baseUrl = process.client ? window.location.origin : `https://${useRequestHeaders()['x-forwarded-host']}`;
  const contentUrl = new URL(route.fullPath, baseUrl);
  let hasRedirected = false;
  const query = `redirect?contentUrl=${contentUrl}`;
  let redirectResult = {} as IRedirectsResult;
  try {
    redirectResult = await apiGet<IRedirectsResult>(query);
  } catch (e) {
    console.log(e);
  }

  if (redirectResult != null && redirectResult.shouldRedirect) {
    hasRedirected = true;
    appInsights.trackTrace('marketUrl: ' + currentMarket?.url + ' + route: ' + route.fullPath + ' = contentUrl: ' + contentUrl + '. Redirected to: ' + redirectResult.newUrl);
    try {
      const newUrl = new URL(redirectResult.newUrl);
      await navigateTo(newUrl.href, {
        redirectCode: redirectResult.permanent ? 301 : 302,
        external: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  if (!hasRedirected && !isVirutalPageListing) {
    let errorUrl = globalContentStore.pageSettings.notFoundPage.url || '';
    if (errorUrl !== '') {
      errorUrl = new URL(errorUrl, websiteUrl).toString();
    }

    const contentResolver = new ContentResolver();
    try {
      result.value = await contentResolver.resolveContent(errorUrl, false);
    } catch (e) {
      console.log(e);
    }
    if (result.value.status !== ResolvedContentStatus.Resolved) {
    // 404 page missing also
      throw new Error(`Page not found - 404 content not found in backend - looked for: "${errorUrl}"`);
    }
    head.title = result.value.title;
    const event = useRequestEvent();
    if (event) {
      setResponseStatus(event, 404);
    }
  }
} else {
  if (page?.value?.content?.name) {
    head.title = page.value.content.name;
  }

  if (isEpiEdit) {
    head.script = [
      {
        src: '/episerver/cms/latest/clientresources/communicationinjector.js',
        body: true,
      },
    ];
  }
}

onMounted(async()=> {
  const cartStore = useCartStore();
  const uiStore = useUiStore();
  cartStore.initCart();
  setTimeout(()=> {
    uiStore.checkLoggedIn();
  }, 1000);
  trackPageView();
  OptanonWrapper();
  onVitePreloadError();
});

// Set history true so that custom nav-back button can use value
const router = useRouter();
router.beforeEach((to, from, next) => {
  if (!sessionStorage.getItem('has-history-on-page')) {
    sessionStorage.setItem('has-history-on-page', 'true');
  }
  next();
});

const pageTitle = computed(() => {
  if (page.value && page.value.content) {
    return page.value.content.name;
  } else if (result.value) {
    return result.value.title;
  }
  return '';
});

const trackPageView = () => {
  window.dataLayer?.push({
    event: 'pageView',
    pageType: 'PageView',
    pageUrl: route.fullPath,
    routeName: 'all',
    pageTitle: pageTitle,
    virtualPage: page.value?.content?.isVirtualPage ? true : false,
  });
  
  appInsights.trackPageView(pageTitle.value, route.fullPath);
};

const OptanonWrapper = () => {
  if (process.client) {
    const win = window as any;
    win.oneTrustPerformanceCookieKey = 'C0003';

    let interval: any;
    let count = 0;
    // Wait for script to load
    interval = setInterval(() => {
      count++;
      if (count < 8) {
        if (win.OneTrust) {
          clearInterval(interval);
          win.OneTrust?.OnConsentChanged(function(event: any) {
            if (event.detail.indexOf(win.oneTrustPerformanceCookieKey) !== -1) {
              enableAiCooies();
            } else {
              disableAndClearAiCookies();
            }
          });
        }
      }
    }, 500); 
  }
};

const disableAndClearAiCookies = () => {
  const win = window as any;

  if (typeof win.appInsights === 'object' && typeof win.appInsights.config === 'object') {
    win.appInsights.config.isCookieUseDisabled = true;
  }
  document.cookie = 'ai_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'ai_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

const enableAiCooies = () => {
  const win = window as any;

  if (typeof win.appInsights === 'object' && typeof win.appInsights.config === 'object') {
    win.appInsights.config.isCookieUseDisabled = false;
    var cookieMgr = win.appInsights.getCookieMgr();
    if (cookieMgr) {
      cookieMgr.setEnabled(true);
      var sessionId = win.appInsights.context.getSessionId();
      if (sessionId) {
        cookieMgr.set('ai_session', sessionId);
        Sentry.setTag('ai_session', sessionId);
      }
      var userId = null;
      if (win.appInsights.context.user && win.appInsights.context.user.id) {
        userId = win.appInsights.context.user.id;
      }
      if (userId) {
        cookieMgr.set('ai_user', userId);
        Sentry.setTag('ai_user', userId);
      }
    }
  }
};

const onVitePreloadError = () => {
  router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location = to.fullPath;
    }
  });

  window.addEventListener('vite:preloadError', () => {
    window.location.reload();
  });
};

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: host,
  'potentialAction': {
    '@type': 'SearchAction',
    'target': host + '/sok/?query={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
};

// Meta
const meta: Ref<MetaBlock> = page?.value ? page?.value.content?.meta : null;
const title = ref(meta?.metaTitle ?? '');
const metaDescription = ref(meta?.metaDescription ?? '');
const openGraphTitle = ref(meta?.openGraphTitle ?? '');
const openGraphDescription = ref(meta?.openGraphDescription ?? '');
const openGraphImage = ref(meta?.openGraphImage ?? '');
const noIndex = ref(meta?.noIndex ?? false);
const canonicalLink = ref(meta?.canonicalLink ?? '');

// Add add page number to title
if (route.query.page) {
  const pageTitle = title.value ? title.value : 'Swedish Match';
  title.value = `Sida ${route.query.page} - ${pageTitle}`;
}

let robots = {};

if (noIndex.value || environmentName !== 'production') {
  robots = {
    hid: 'robots',
    name: 'robots',
    content: 'noindex,nofollow',
  };
}

if (page.value?.content?.isVirtualPage) {
  robots = {
    hid: 'robots',
    name: 'robots',
    content: 'noindex',
  };
}

let metaSearchConsole = {};

if (globalContentStore.marketSettings.countryCode === 'NO') {
  metaSearchConsole = {
    name: 'google-site-verification',
    content: 'bBPdpVpN0l8W-8OI6bBaKqFJWxyGl48OnhSdsBoh2lo',
  };
}

useJsonld(jsonLd);

useHead({
  title,
  meta: [
    {
      hid: 'title',
      name: 'title',
      content: title.value ? title.value : 'Swedish Match',
    },
    {
      name: 'description',
      content: metaDescription.value ? metaDescription : '',
    },
    {
      hid: 'google-site-verification',
      name: 'google-site-verification',
      content: googleSiteVerification,
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      content: 'Swedish Match',
    },
    {
      hid: 'og:title',
      name: 'og:title',
      content: openGraphTitle.value ? openGraphTitle : '',
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: openGraphDescription.value
        ? openGraphDescription
        : '',
    },
    {
      hid: 'og:image',
      name: 'og:image',
      content: openGraphImage.value
        ? openGraphImage?.value.url.includes(host) ? openGraphImage?.value.url : host + openGraphImage?.value.url
        : '',
    },
    {
      hid: 'og:type',
      name: 'og:type',
      content: 'website',
    },
    robots,
    metaSearchConsole,
  ],
  link: [
    {
      rel: 'canonical',
      href: canonicalLink.value ? canonicalLink : host + route.path,
    },
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      href: '/favicons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/favicons/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/favicons/safari-pinned-tab.svg',
      color: '#085778',
    },
    {
      rel: 'shortcut icon',
      href: '/favicons/favicon.ico',
    },
  ],
  htmlAttrs: {
    lang: marketSettings.contentLanguage ? marketSettings.contentLanguage : '',
  },
});
</script>
